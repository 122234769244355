import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { first, finalize, mergeMap } from 'rxjs/operators';
import { RouteUtils } from 'src/app/shared/utils/route-utils';
import { of, Subscription } from 'rxjs';
import { FormUtilities } from 'src/app/shared/input/form/form-utilities';
import { ToastService } from 'src/app/shared/presentation/toast.service';
import { ConfirmRegistration, Signup } from '../../state/auth.actions';
import { SignUpRequest } from '../../state/auth.models';
import { Account } from 'src/app/dash/accounts/state/account-provider.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit, OnDestroy {
  form: FormGroup;
  pageLoaded: boolean;
  loading: boolean;
  emailSent: boolean;
  private subs: Subscription[];

  constructor(private router: Router, private store: Store, private toast: ToastService, private route: ActivatedRoute) {
    this.pageLoaded = false;
    this.loading = false;
    this.emailSent = false;
    this.subs = [];
  }

  ngOnInit() {
    this.form = new FormGroup({
      name: new FormControl(null, Validators.required),
      email: new FormControl(null, [Validators.required, Validators.email]),
      passwords: new FormGroup({
        password: new FormControl(null, [Validators.required, Validators.minLength(8)]),
        confirmPassword: new FormControl(null, Validators.required)
      }, FormUtilities.matchPasswordValidator),
      privacy: new FormControl(false, Validators.required)
    });
    this.subs.push(RouteUtils.getParam(this.route, 'token').pipe(
      mergeMap((token: string) => !!token ? this.store.dispatch(new ConfirmRegistration(token)) : of(null)),
    ).subscribe({
      next: (res: Account) => {
        if (!!res) {
          this.router.navigateByUrl('dash');
        } else {
          this.pageLoaded = true;
        }
      },
      error: () => this.toast.error('[signup].signup error')
    }));
    this.subs.push(RouteUtils.getParam(this.route, 'email').subscribe((email: string) => {
      if (!!email) {
        this.form.get('email').setValue(email);
        this.form.get('email').disable();
      }
    }));
  }

  ngOnDestroy() {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }

  submitForm() {
    if (!this.form.valid) {
      FormUtilities.markFormAsTouched(this.form);
      this.toast.error('check form fields');
      return;
    }
    this.loading = true;
    const request: SignUpRequest = {
      name: this.form.get('name').value,
      email: this.form.get('email').value,
      password: this.form.get('passwords.password').value
    };
    this.subs.push(this.store.dispatch(new Signup(request)).pipe(
      first(),
      finalize(() => this.loading = false)
    ).subscribe({
      next: () => this.emailSent = true,
      error: error => this.toast.error(error.status === 409 ? '[signup].signup error taken' : '[signup].signup error')
    }));
  }

  back() {
    this.router.navigateByUrl('signin');
  }
}
