import { Directive, Input, TemplateRef, ViewContainerRef, OnInit, OnDestroy } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { AccountUtilsService } from 'src/app/shared/utils/account-utils.service';
import { Account } from 'src/app/dash/accounts/state/account-provider.service';
import { Role } from './state/auth.models';
import { AuthState } from './state/auth.state';

@Directive({
  selector: '[ifAuth]'
})
export class IfAuthDirective implements OnInit, OnDestroy {
  @Select(AuthState) private auth$: Observable<Account>;
  @Input() ifAuth: string[];
  private hasView: boolean = false;
  private sub: Subscription | undefined;

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private accountUtils: AccountUtilsService) { }

  ngOnInit() {
    this.sub = this.auth$.subscribe((account: Account) => {
      const roles: Role[] = Object.values(Role).filter((role: Role) => this.ifAuth.includes(role));
      const condition: boolean = this.accountUtils.hasAuthorization(account, roles);
      if (condition && !this.hasView) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.hasView = true;
      } else if (!condition && this.hasView) {
        this.viewContainer.clear();
        this.hasView = false;
      }
    });
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }
}
