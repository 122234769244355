import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, FormGroup, ValidationErrors, Validator, Validators } from '@angular/forms';
import ISO6391, { LanguageCode } from 'iso-639-1';
import { Subscription } from 'rxjs';
import { GenericFormControlAccessors } from 'src/app/shared/input/form/generic-form-control-accessor';

@Component({
  selector: 'app-language-selector',
  providers: GenericFormControlAccessors.valueAndValidator(LanguageSelectorComponent),
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements ControlValueAccessor, Validator, OnInit, OnDestroy {
  @Input() excludedLanguages: LanguageCode[] = [];
  form: FormGroup;
  filteredLanguages: LanguageSelectorData[];
  private languages: LanguageSelectorData[];
  private subs: Subscription[] = [];
  private onChange: (arg: LanguageCode) => void;

  get languageControl(): FormControl {
    return this.form.get('language') as FormControl;
  }

  ngOnInit() {
    this.languages = ISO6391.getLanguages(ISO6391.getAllCodes()).filter(lang => !this.excludedLanguages.includes(lang.code));
    this.filteredLanguages = this.languages;
    this.form = new FormGroup({
      language: new FormControl(null),
      filter: new FormControl(null)
    });
    this.subs.push(this.form.get('filter').valueChanges.subscribe((search: string) => this.filteredLanguages = !!search ?
      this.languages.filter((item: LanguageSelectorData) => item.nativeName.toLowerCase().includes(search.toLowerCase())) : this.languages
    ));
  }

  ngOnDestroy() {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }

  writeValue(value: LanguageCode): void {
    if (!!value) {
      this.languageControl.setValue(value);
    }
    this.subs.push(this.languageControl.valueChanges.subscribe((language: LanguageCode) => this.onChange(language)));
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(_fn: any): void { }

  validate(control: AbstractControl): ValidationErrors | null {
    if (control.hasValidator(Validators.required)) {
      this.languageControl.addValidators(Validators.required);
    }
    return this.languageControl.errors;
  }
}

export interface LanguageSelectorData {
  code: LanguageCode;
  name: string;
  nativeName: string;
}
