import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-language-tabs',
  templateUrl: './language-tabs.component.html',
  styleUrls: ['./language-tabs.component.scss']
})
export class LanguageTabsComponent implements OnChanges {
  @Input() languages: string[];
  @Input() selected: string;
  @Output() selectedChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() addTab: EventEmitter<any> = new EventEmitter<any>();
  selectedIndex: number = 0;

  ngOnChanges(): void {
    const selectedIndex: number = this.languages.indexOf(this.selected);
    this.selectedIndex = selectedIndex !== -1 ? selectedIndex : this.languages.length - 1;
  }

  switchLanguage(event: MatTabChangeEvent) {
    this.selectedChange.emit(event.tab.textLabel.toLowerCase());
  }
}
