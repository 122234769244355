// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  font-size: 40px;
  max-width: 1em;
  max-height: 1em;
  display: inline-block;
}
:host.help-tooltip {
  position: absolute;
  margin-top: -18px;
  margin-left: -10px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImljb24uY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxlQUFBO0VBQ0EsY0FBQTtFQUNBLGVBQUE7RUFDQSxxQkFBQTtBQUNGO0FBQ0U7RUFDRSxrQkFBQTtFQUNBLGlCQUFBO0VBQ0Esa0JBQUE7QUFDSiIsImZpbGUiOiJpY29uLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiOmhvc3Qge1xuICBmb250LXNpemU6IDQwcHg7XG4gIG1heC13aWR0aDogMWVtO1xuICBtYXgtaGVpZ2h0OiAxZW07XG4gIGRpc3BsYXk6IGlubGluZS1ibG9jaztcblxuICAmLmhlbHAtdG9vbHRpcCB7XG4gICAgcG9zaXRpb246IGFic29sdXRlO1xuICAgIG1hcmdpbi10b3A6IC0xOHB4O1xuICAgIG1hcmdpbi1sZWZ0OiAtMTBweDtcbiAgfVxufSJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/shared/presentation/icon/icon.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,cAAA;EACA,eAAA;EACA,qBAAA;AACF;AACE;EACE,kBAAA;EACA,iBAAA;EACA,kBAAA;AACJ;AACA,4lBAA4lB","sourcesContent":[":host {\n  font-size: 40px;\n  max-width: 1em;\n  max-height: 1em;\n  display: inline-block;\n\n  &.help-tooltip {\n    position: absolute;\n    margin-top: -18px;\n    margin-left: -10px;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
