import { Injectable } from "@angular/core";
import { JwtHelperService } from "./jwthelper.service";
import { Account } from 'src/app/dash/accounts/state/account-provider.service';
import { Museum, MuseumAuthorization, MuseumRole } from 'src/app/dash/museums/state/museum.models';
import { Role } from "src/app/auth/state/auth.models";

@Injectable({
  providedIn: 'root'
})
export class AccountUtilsService {
  constructor() { }

  hasAuthorization(account: Account, roles: Role[]): boolean {
    return roles.includes(account.role as Role);
  }

  isRootOrAdmin(account: Account, museum: Museum): boolean {
    if (account.role === Role.ROOT) {
      return true;
    }
    return !!museum.authorizationList.find((item: MuseumAuthorization) => item.accountId === account.id && item.role === MuseumRole.ADMIN);
  }

  getMuseumAuthorizationList(account: Account, museum: Museum): MuseumAuthorization[] {
    if (account.role === Role.ROOT) {
      return museum.authorizationList;
    }
    return museum.authorizationList.filter((item: MuseumAuthorization) => item.account.role === Role.USER);
  }

  decodeJwt(token: string): any {
    return new JwtHelperService().decodeToken(token);
  }

  gotUserInfo(account: Account): boolean {
    return account.email != null;
  }
}
