import { Component, ViewChild, ElementRef, Renderer2, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss']
})
export class ActionsComponent implements AfterViewInit {
  @ViewChild('content', { static: false }) private content: ElementRef;
  @ViewChild('spacer', { static: false }) private spacer: ElementRef;

  constructor(private renderer: Renderer2) { }

  ngAfterViewInit(): void {
    this.onResized();
  }

  onResized() {
    this.renderer.setStyle(this.spacer.nativeElement, 'min-height', this.content.nativeElement.offsetHeight + 'px');
  }
}
