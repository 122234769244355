import { FormControl, Validators, FormGroup, AbstractControl, FormArray, ValidatorFn } from '@angular/forms';
import forOwn from 'lodash/forOwn';
import Quill from 'quill';
import QuillPasteSmart from 'quill-paste-smart';

export class FormUtilities {
  static phoneValidator: ValidatorFn = Validators.pattern(/^[0-9 \-+,.()]*$/);
  static urlValidator: ValidatorFn = Validators.pattern(/^(http|https|ftp):\/\/(-.)?([^\s/?.#-]+.?)+(\/[^\s]*)?$/i);
  static youtubeUrlValidator: ValidatorFn =
    Validators.pattern(/^(https:\/\/)(www\.)?(youtube\.com|youtu\.be)\/(watch\?v=|embed\/|v\/|.+\?v=)?([a-zA-Z0-9_-]{11})(?:[&=%\?].*)?$/i);
  static quillConfig = {
    toolbar: [
      [{ 'header': [1, 2, 3, false] }],
      ['bold', 'italic', 'underline'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ['link']
    ]
  };

  static initQuillSmartPaste() {
    Quill.register('modules/clipboard', QuillPasteSmart, true);
  }

  static nullifyEmptyString(control: FormControl) {
    if (control.value === '') {
      control.setValue(null);
    }
    return null;
  }

  static matchPasswordValidator(AC: AbstractControl): { [key: string]: boolean } {
    const password: AbstractControl = AC.get('password');
    const confirmPassword: AbstractControl = AC.get('confirmPassword');
    if (!password || !confirmPassword) {
      return null;
    }
    if (password.value !== confirmPassword.value) {
      return { matchPassword: true };
    }
    return null;
  }

  static markFormAsTouched(form: FormGroup) {
    FormUtilities.markFormGroupTouchedRecursive(form.controls);
  }

  private static markFormGroupTouchedRecursive = (controls: { [key: string]: AbstractControl } | AbstractControl[]): void => {
    forOwn(controls, c => {
      if (c instanceof FormGroup || c instanceof FormArray) {
        FormUtilities.markFormGroupTouchedRecursive(c.controls);
      } else if (c instanceof FormControl) {
        c.markAsTouched();
      }
    });
  }
}
