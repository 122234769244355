import { Museum } from "../dash/museums/state/museum.models";

export class DeselectMuseum {
  static readonly type = '[Museums] Deselect';
}

export class SelectMuseum {
  static readonly type = '[Museums] Select';
  constructor(public payload: Museum) { }
}

export class FetchSelectedMuseum {
  static readonly type = '[Museums] Fetch Selected';
  constructor(public payload: number) { }
}

export class FetchMuseumCredits {
  static readonly type = '[Museums] Fetch Credits';
  constructor(public force?: boolean) { }
}
