import { Injectable } from '@angular/core';
import { SwUpdate, VersionEvent, VersionReadyEvent } from '@angular/service-worker';
import { TranslateService } from '@ngx-translate/core';
import { filter, map, mergeMap } from 'rxjs/operators';
import { zip } from 'rxjs';
import { MatSnackBar, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';

@Injectable()
export class PwaUpdateService {
  constructor(private translate: TranslateService, private swUpdate: SwUpdate, private snackbar: MatSnackBar) { }

  check() {
    const updatesAvailable = this.swUpdate.versionUpdates.pipe(
      filter((evt: VersionEvent): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
      map(evt => ({
        type: 'UPDATE_AVAILABLE',
        current: evt.currentVersion,
        available: evt.latestVersion,
      })));
    updatesAvailable.pipe(mergeMap(() =>
      zip(this.translate.get('[pwa-updates].description'), this.translate.get('[pwa-updates].cta'))
    )).subscribe(([description, cta]) => {
      const snack: MatSnackBarRef<TextOnlySnackBar> = this.snackbar.open(description, cta);
      snack.onAction().subscribe(() => window.location.reload());
      setTimeout(() => snack.dismiss(), 12000);
    });
  }
}
