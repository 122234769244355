import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription, map } from 'rxjs';
import { MultipleSelectDialogData } from './multiple-select-dialog.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-multiple-select-dialog',
  templateUrl: './multiple-select-dialog.component.html',
  styleUrls: ['./multiple-select-dialog.component.scss'],
})
export class MultipleSelectDialogComponent implements OnInit, OnDestroy {
  form: FormGroup;
  filteredList$: Observable<any[]>;
  private sub: Subscription | undefined;

  private get selectedValues(): any[] {
    return this.form.get('select').value as any[];
  }

  constructor(
    public dialogRef: MatDialogRef<MultipleSelectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MultipleSelectDialogData
  ) { }

  ngOnInit() {
    this.form = new FormGroup({
      select: new FormControl(this.data.selectedValues, this.data.required ? Validators.required : null),
      filter: new FormControl(null)
    });
    this.filteredList$ = this.data.list$;
    this.sub = this.form.get('filter').valueChanges.subscribe((search: string) => this.filteredList$ = !search ? this.data.list$ :
      this.data.list$.pipe(map((list: any[]) => list.filter(item => item[this.data.displayField].toLowerCase().includes(search.toLowerCase()))))
    );
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }

  confirm() {
    if (this.form.valid) {
      this.dialogRef.close(this.selectedValues);
    } else {
      this.form.get('select').markAsTouched();
    }
  }

  onItemRemoved(item: any) {
    this.selectedValues.splice(this.selectedValues.indexOf(item), 1);
    this.form.get('select').setValue(this.selectedValues);
  }

  selectedValues$(): Observable<any[]> {
    return this.data.list$.pipe(map((list: any[]) => this.selectedValues.map(value =>
      list.find(item => item[this.data.displayField] === value[this.data.displayField])
    )));
  }

  reorderChips(event: CdkDragDrop<any>) {
    moveItemInArray(this.selectedValues, event.previousContainer.data.index, event.container.data.index);
  }
}
