import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AuthState } from '../state/auth.state';
import { AccountUtilsService } from 'src/app/shared/utils/account-utils.service';
import { Account } from 'src/app/dash/accounts/state/account-provider.service';
import { Logout } from '../state/auth.actions';


@Injectable()
export class IsLoggedGuardService {
  @Select(AuthState) private auth$: Observable<Account>;
  private user: Account;

  constructor(private store: Store, private router: Router, private accountUtils: AccountUtilsService) {
    this.auth$.subscribe((res: Account) => this.user = res);
  }

  canActivate(_route: ActivatedRouteSnapshot): boolean {
    if (this.isTokenExpired(this.user.token)) {
      this.store.dispatch(new Logout()).subscribe(() => this.router.navigateByUrl('signin'));
      return false;
    }
    if (this.user.id != null) {
      return true;
    } else {
      this.router.navigate(['./403']);
      return false;
    }
  }

  private isTokenExpired(token: string): boolean {
    return !!token ? this.accountUtils.decodeJwt(token).exp < Date.now().valueOf() / 1000 : false;
  }
}
