import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ToastService {
  constructor(private snackBar: MatSnackBar, private translate: TranslateService) { }

  success(message: string, params?: any) {
    this.show('success', message, params);
  }

  error(message: string, params?: any) {
    this.show('error', message, params);
  }

  private show(panelClass: string, message: string, params?: any) {
    this.translate.get(message, params).subscribe((res: string) => this.snackBar.open(res, null, { duration: 3000, panelClass }));
  }
}
