import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  @Input() icon: string;
  @Input() text: string;
  @Input() type: string = 'button';
  @Input() disabled: boolean = false;
  @Output() onClick: EventEmitter<any> = new EventEmitter();
}
