import { Guide, SetPiecesRequest, UpdateGuideRequest } from "./guide.models";

export class ResetGuides {
  static readonly type = '[Guides] Reset';
}

export class FetchAllGuides {
  static readonly type = '[Guides] Fetch All';
  constructor(public force?: boolean) { }
}

export class FetchGuide {
  static readonly type = '[Guides] Fetch';
  constructor(public payload: number) { }
}

export class CreateGuide {
  static readonly type = '[Guides] Create';
  constructor(public payload: Guide) { }
}

export class UpdateGuide {
  static readonly type = '[Guides] Update';
  constructor(public payload: UpdateGuideRequest) { }
}

export class UpdateGuidesOrder {
  static readonly type = '[Guides] Update Order';
  constructor(public idsInOrder: number[]) { }
}

export class SetGuidePieces {
  static readonly type = '[Guides] Set Pieces';
  constructor(public payload: SetPiecesRequest) { }
}

export class DeleteGuide {
  static readonly type = '[Guides] Delete';
  constructor(public payload: number) { }
}

export class TabChangeGuide {
  static readonly type = '[Guides] Tab Change';
}
