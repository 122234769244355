import { mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, of, EMPTY } from 'rxjs';
import { SelectLanguageDialogComponent } from './select-language-dialog.component';
import { LanguageCode } from 'iso-639-1';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class SelectLanguageDialogService {
  constructor(private dialog: MatDialog) { }

  open(data: SelectLanguageDialogData): Observable<LanguageCode> {
    const dialogRef = this.dialog.open(SelectLanguageDialogComponent, { data });
    return dialogRef.afterClosed().pipe(mergeMap((res: LanguageCode) => !!res ? of(res) : EMPTY));
  }
}

export interface SelectLanguageDialogData {
  excludedLanguages: LanguageCode[];
}
