import { Injectable } from '@angular/core';
import { Observable, of, EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ConfirmDialogComponent } from './confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class ConfirmDialogService {

  constructor(private dialog: MatDialog) { }

  open(data: ConfirmDialogData): Observable<boolean> {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, { data });
    return dialogRef.afterClosed().pipe(mergeMap((res: boolean) => (res === true) ? of(true) : EMPTY));
  }
}

export interface ConfirmDialogData {
  icon: string;
  title: string;
  text?: string;
  confirmByTyping?: string;
}
