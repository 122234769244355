import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatIconRegistry } from '@angular/material/icon';;
import { Observable, Subscription } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { GlobalState } from './state/global.state';
import { Router, NavigationEnd } from '@angular/router';
import { ResetLoading } from './state/global.actions';
import localeIt from '@angular/common/locales/it';
import localeItExtra from '@angular/common/locales/extra/it';
import { registerLocaleData } from '@angular/common';
import { PwaUpdateService } from './shared/utils/pwa-update.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  @Select(GlobalState.loading) loading$: Observable<boolean>;
  private sub: Subscription | undefined;

  constructor(
    public translate: TranslateService,
    public matIconRegistry: MatIconRegistry,
    private pwaUpdates: PwaUpdateService,
    private router: Router,
    private store: Store
  ) {
    translate.setDefaultLang('en');
    if (navigator.language.startsWith('it')) {
      registerLocaleData(localeIt, 'it', localeItExtra);
      translate.use('it');
    } else {
      translate.use('en');
    }
    matIconRegistry.registerFontClassAlias('icon');
  }

  ngOnInit() {
    this.pwaUpdates.check();
    // always disable preloading after routing
    this.sub = this.router.events.subscribe((res: NavigationEnd) => {
      if (res instanceof NavigationEnd) {
        this.store.dispatch(new ResetLoading());
      }
    });
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }
}
