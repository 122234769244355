import { Component, Inject } from "@angular/core";
import { QRCodeElementType } from "angularx-qrcode";
import { ClipboardService } from "ngx-clipboard";
import { QRDialogData } from "./qr-dialog.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: 'app-qr-dialog',
  templateUrl: './qr-dialog.component.html',
  styleUrls: ['../dialog-component.scss'],
})
export class QrDialogComponent {
  elementType: QRCodeElementType = "canvas";

  constructor(
    public dialogRef: MatDialogRef<QrDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: QRDialogData,
    private clipboard: ClipboardService
  ) { }

  copy(payload: string) {
    this.clipboard.copyFromContent(payload);
  }

  download(parent: any) {
    let parentElement = null;
    if (this.elementType === "canvas") { // fetches base 64 data from canvas
      parentElement = parent.qrcElement.nativeElement.querySelector("canvas").toDataURL("image/png");
    } else if (this.elementType === "img" || this.elementType === "url") {
      // fetches base 64 data from image
      // parentElement contains the base64 encoded image src
      // you might use to store somewhere
      parentElement = parent.qrcElement.nativeElement.querySelector("img").src;
    } else {
      alert("Set elementType to 'canvas', 'img' or 'url'.");
    }
    if (parentElement) { // converts base64 to blob
      let blobData: Blob = this.convertBase64ToBlob(parentElement);
      const blob: Blob = new Blob([blobData], { type: "image/png" });  // saves as image
      const url: string = window.URL.createObjectURL(blob);
      const link: HTMLAnchorElement = document.createElement("a");
      link.href = url;
      link.download = "angularx-qrcode";  // name of the file
      link.click();
    }
  }

  private convertBase64ToBlob(Base64Image: string): Blob {
    const parts: string[] = Base64Image.split(";base64,");  // split into two parts
    const imageType: string = parts[0].split(":")[1];  // hold the content type
    const decodedData: string = window.atob(parts[1]);  // decode base64 string
    const uInt8Array: Uint8Array = new Uint8Array(decodedData.length);  // create unit8array of size same as row data length
    for (let i = 0; i < decodedData.length; ++i) {  // insert all character code into uint8array
      uInt8Array[i] = decodedData.charCodeAt(i);
    }
    return new Blob([uInt8Array], { type: imageType });  // return blob image after conversion
  }
}
