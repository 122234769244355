import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { tap, first, map } from 'rxjs/operators';
import { AuthState } from '../state/auth.state';
import { Select } from '@ngxs/store';
import { AccountUtilsService } from 'src/app/shared/utils/account-utils.service';
import { Account } from 'src/app/dash/accounts/state/account-provider.service';

@Injectable()
export class HasAuthorizationGuardService  {
  @Select(AuthState)private auth$: Observable<Account>;

  constructor(private router: Router,private accountUtils: AccountUtilsService) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.auth$.pipe(
      first(),
      map((res: Account) => this.accountUtils.hasAuthorization(res, route.data.roles)),
      tap((hasAuth: boolean) => {
        if (!hasAuth) {
          this.router.navigate(['./403']);
        }
      })
    );
  }
}
